import React, { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'

interface IProps {
  children: React.ReactNode
  animated?: boolean
  className?: string
}

const Slides: React.FC<IProps> = ({ children, animated, className }) => {
  const isAnimated = animated !== undefined ? animated : true
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 500)
    return () => clearTimeout(timer)
  }, [])
  return (
    <>
      {!isAnimated ? (
        <>{children}</>
      ) : (
        <Fade
          bottom
          duration={800}
          distance={'min(150px, 12vh)'}
          className={className}
          when={isVisible}
        >
          {children}
        </Fade>
      )}
    </>
  )
}

export default Slides
